










import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import MessageCardItem from "@/views/Communication/Messaging/MessageCardItem.vue";

@Component({
  components: {
    MessageCardItem,
  },
})
export default class MessageList extends Vue {
  public name = "MessageList";

  @Prop()
  public messages!: Array<any>;

  @Prop()
  public loading!: any;

  public selectedMessage?: any | null = {};

  public onSelectMessage(message: any) {
    this.selectedMessage = message;
    this.$emit("select-message", message);
  }
}
