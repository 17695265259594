














































































import { Component, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import MessageForm from "@/views/Communication/Messaging/MessageForm.vue";
import { namespace } from "vuex-class";
import MessageList from "@/views/Communication/Messaging/MessageList.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import SettingsButton from "@/components/Button/SettingsButton.vue";
import { mixins } from "vue-class-component";
import FuncMixins from "@/mixins/FuncMixins";
import MagicStickButton from "@/components/Button/MagicStickButton.vue";

const MessagingModule = namespace("messaging");
const MessagingFilterModule = namespace("messaging/localFilter");
const MessagingTemplatesModule = namespace("message-templates");

@Component({
  components: {
    FscPageHeader,
    FilterAndSearch,
    Actions,
    CreateButton,
    MessageForm,
    MessageList,
    ArchiveButton,
    ArchiveModal,
    FscMultiselect,
    SettingsButton,
    MagicStickButton,
  },
})
export default class Messaging extends mixins(FuncMixins) {
  public name = "Messaging";

  @MessagingModule.Action("findAll")
  public findAllMessages: any;

  @MessagingModule.Getter("getDataList")
  public allMessages: any;

  @MessagingModule.Action("create")
  public sendingMessage: any;

  @MessagingModule.Action("update")
  public updatingMessage: any;

  @MessagingModule.Action("archive")
  public archivingMessage: any;

  @MessagingModule.Getter("getSuccess")
  public success: any;

  @MessagingModule.Getter("getIsLoading")
  public loading: any;

  @MessagingModule.Getter("getArchiveSuccess")
  private archiveSuccess: any;

  @MessagingFilterModule.State("filter")
  public messagesLocalFilter: any;

  @MessagingFilterModule.Mutation("SET_LOCAL_FILTER")
  public setMessagesLocalFilter!: (payload: Record<any, any>) => void;

  @MessagingTemplatesModule.Getter("getTemplates")
  private getMessageTemplates: any;

  @MessagingTemplatesModule.Action("getTemplatesAllAction")
  private getMessageTemplatesAction: any;

  public formIsNew = false;
  public formOpen = false;
  public selectedMessage: any | null = {};
  public selectedMessageStatus: any = {
    id: 2,
    name: this.$tc("general.active", 1),
    value: "active",
    archived: false,
  };
  public searchTerm: any = "";

  public get messageStatusOptions() {
    return [
      {
        id: 1,
        name: this.$t("todo.all"),
        value: "all",
      },
      {
        id: 2,
        name: this.$tc("general.active", 1),
        value: "active",
        archived: false,
      },
      {
        id: 3,
        name: this.$tc("general.archived", 1),
        value: "archived",
        archived: true,
      },
    ];
  }

  private get sortedMessages() {
    if (!this.allMessages || this.allMessages.length === 0) return [];
    return this.allMessages
      .map((message: any) => {
        return {
          ...message,
          dateToMilliseconds: Date.parse(message.createdOn),
        };
      })
      .sort((a: any, b: any) => (a.dateToMilliseconds <= b.dateToMilliseconds ? 1 : -1));
  }

  private saveLocalFilter() {
    this.setMessagesLocalFilter({ searchTerm: this.searchTerm, messageStatus: this.selectedMessageStatus });
  }

  private onChangeMessageStatus() {
    this.saveLocalFilter();
  }

  public mounted() {
    this.onChangeMessageStatus();
    this.findAllMessages({
      resource: "messages",
    });
    this.getMessageTemplatesAction();
    if (this.messagesLocalFilter instanceof Object) {
      const { messageStatus, searchTerm } = this.messagesLocalFilter;
      this.selectedMessageStatus = messageStatus;
      this.searchTerm = searchTerm;
    } else {
      this.selectedMessageStatus = this.messageStatusOptions[0];
    }
  }

  public closeMessageForm() {
    this.selectedMessage = null;
    this.formOpen = false;
  }

  public createMessage() {
    if (this.formOpen) {
      this.selectedMessage = null;
    }
    this.formIsNew = true;
    this.formOpen = true;
  }

  public onSearchMessages(value: string) {
    this.debouncer(() => {
      this.searchTerm = value;
      this.saveLocalFilter();
    });
  }

  public async onMessageSend(message: any) {
    if (this.selectedMessage && this.selectedMessage.id) {
      const updatedMessage = {
        id: this.selectedMessage ? this.selectedMessage.id : null,
        subject: message.subject,
        students: message.students,
        body: message.body,
      };
      await this.updatingMessage({
        resource: "messages",
        data: updatedMessage,
        id: this.selectedMessage.id,
      });
    } else {
      await this.sendingMessage({
        resource: "messages",
        data: message,
      });
    }

    if (this.success) {
      this.onSuccessChange();
    }
  }

  public onSelectMessage(message: any) {
    if (message.archived) {
      this.formIsNew = false;
      this.formOpen = false;
      this.selectedMessage = message;
    } else {
      this.formIsNew = false;
      this.formOpen = true;
      this.selectedMessage = message;
    }
  }

  public get formMainTitle() {
    let title = "";
    if (this.formOpen && this.formIsNew) {
      title = this.$tc("sidebar.new_message");
    } else if (this.formOpen && !this.formIsNew) {
      title = this.selectedMessage.subject;
    }
    return title;
  }

  public onSuccessChange() {
    this.findAllMessages({
      resource: "messages",
    });
    this.closeMessageForm();
  }

  @Watch("$route", { immediate: true, deep: true })
  public onRouteChange(newRoute: any, oldRoute: any) {
    if (newRoute.name === "Messaging" && newRoute?.query?.newForm == "true") {
      this.createMessage();
    }
  }

  @Watch("archiveSuccess")
  public async onArchiveSuccessChange(archiveSuccess: any) {
    if (archiveSuccess) {
      await this.findAllMessages({
        resource: "messages",
      });
      this.closeMessageForm();
    }
  }

  public get filteredMessages() {
    let localMessages = this.sortedMessages;
    if (!localMessages || localMessages.length == 0) {
      return [];
    }
    localMessages = localMessages.filter((message: any) => message.subject?.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1);

    switch (this.selectedMessageStatus?.id) {
      case 2:
        return localMessages.filter((message: any) => message.archived === false);
      case 3:
        return localMessages.filter((message: any) => message.archived === true);
      default:
        return localMessages;
    }
  }

  public showArchiveModal() {
    if (!this.selectedMessage || !this.selectedMessage?.id) return;
    this.$bvModal.show("message-archive-modal");
  }

  public onOpenTemplateSettings() {
    this.$router.push({ name: "MessageTemplates" });
  }

  public automaticMessagesRedirect() {
    this.$router.push({ name: "AutomaticMessages" });
  }
}
