























































































import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import Tinymce from "@/components/Editor/Tinymce.vue";
import SendMailButton from "@/components/Button/SendMailButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import { namespace } from "vuex-class";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { formatStudentName } from "@/utils/NameUtil";

const StudentModule = namespace("student");

@Component({
  components: {
    FscSimpleCard,
    FscMultiselect,
    Tinymce,
    SendMailButton,
    AbortButton,
    Validation,
  },
})
export default class MessageForm extends Vue {
  public name = "MessageForm";

  @StudentModule.Action("findStudentEmails")
  public findAllStudents: any;

  @StudentModule.Mutation("SET_SELECTED_STUDENT")
  public setCurrentSelectedStudent: any;

  @StudentModule.Getter("getStudentEmails")
  public allStudents: any;

  @StudentModule.Getter("getSelectedStudent")
  public getCurrentSelectedStudent: any;

  @Prop()
  public message!: any;

  @Prop()
  public formIsNew!: any;

  @Prop({ type: Array, default: () => [] })
  public templates: any;

  public selectedStudents: any = [];
  public subject = "";
  public body = "";
  public template: any = {};

  @Validations()
  public validations(): Record<string, any> {
    return {
      selectedStudents: { required },
      subject: { required },
    };
  }

  public mounted() {
    this.selectedStudents = [];
    this.findAllStudents();
    this.$root.$on("selectedStudent", (student: any) => {
      this.$emit("message-clear");
      //comment these lines (set timeout) if customer decides to not clean message up by switching between students
      setTimeout(() => {
        this.body = "";
        this.subject = "";
        this.template = null;
      }, 500);
      this.selectedStudents = [];
      this.selectedStudents.push(student);
      this.setCurrentSelectedStudent(student);
    });
  }

  public get studentOptions(): Array<any> {
    if (this.allStudents) {
      return this.allStudents?.map((student: any) => ({
        name: formatStudentName(student.firstName, student.lastName),
        id: student.id,
      }));
    }
    return [];
  }

  public onMessageSend() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const message = {
      subject: this.subject,
      students: this.selectedStudents,
      body: this.body,
    };
    this.$emit("message-send", message);
  }

  public onMessageAbort() {
    this.$emit("message-abort");
  }
  public onSelectTemplate(e: any) {
    if (e) {
      this.subject = this.template.subject || e.subject;
      this.body = this.template.body;
    }
  }

  @Watch("message", { deep: true, immediate: true })
  public onMessageChange(messageValue: any, oldMessage: any) {
    if (messageValue && messageValue.id) {
      this.selectedStudents = messageValue.students;
      this.subject = messageValue.subject;
      this.body = messageValue.body;
    }
    // } else {
    //   this.selectedStudents = [];
    //   this.subject = this.template?.subject || "";
    //   this.body = "";
    //   this.template = null;
    // }
  }

  public onSelectedStudentsChange(newVal: any) {
    this.setCurrentSelectedStudent(newVal);
  }

  @Watch("$route", { immediate: true, deep: true })
  public async onRouteChange(route: any, oldRoute: any) {
    if (route && route.name === "Messaging") {
      this.selectedStudents = Array.isArray(this.getCurrentSelectedStudent) ? this.getCurrentSelectedStudent : [this.getCurrentSelectedStudent];
    }
  }
}
