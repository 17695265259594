




























import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: {
    FscSimpleCard,
  },
})
export default class MessageCardItem extends Vue {
  public name = "MessageCardItem";

  @Prop()
  public message?: any;

  @Prop()
  public classSelect!: any;
}
